import React from "react";

export const DownLoad = ({
  color = "blue",
  size = "100%",
  className = "",
  style = {},
  ...props
}) => (
  <svg
    viewBox="0 0 39.272 36"
    className={`svg-icon ${className || ""}`}
    style={{ width: size, ...style }}
    {...props}
  >
    <path
      id="Union_13"
      data-name="Union 13"
      fill={color}
      d="M-4299.419,36a.581.581,0,0,1-.581-.581V28.452a.581.581,0,0,1,.581-.581.581.581,0,0,1,.581.581v6.386h33.678V28.452a.58.58,0,0,1,.581-.581.582.582,0,0,1,.581.581v6.967a.582.582,0,0,1-.581.581Zm16.944-6.054-8.128-11.613a.58.58,0,0,1-.04-.6.581.581,0,0,1,.515-.312h6.968V.581a.581.581,0,0,1,.581-.581h1.161a.581.581,0,0,1,.581.581V17.42h6.968a.582.582,0,0,1,.515.312.59.59,0,0,1,.065.269.577.577,0,0,1-.1.332l-8.128,11.613a.582.582,0,0,1-.476.248A.579.579,0,0,1-4282.476,29.946Z"
      transform="translate(4300)"
    />
  </svg>
);
