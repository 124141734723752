/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import qs from "query-string";
import { withRouter } from "react-router-dom";
import { has } from "lodash";

import NavBar from "../../partials/NavBar";
import SideBar from "../../partials/SideBar";
import "../../style/default.css";
import ErrorBoundary from "../../../components/ErrorBoundary";
import Message from "../../partials/Message";
import {
  getUser,
  LOGIN_URL,
  refreshUrl,
  USERACCESSCONTROL,
  USERDATA,
  USERTOKEN,
  userUrl,
} from "../../utils/data";
import { axiosFunc } from "../../utils/helper";
import {
  AdminAccessControl,
  ProviderAccessControl,
  SubProviderAccessControl,
} from "../../utils/access_control";
import { Notification } from "../../../components/common/notification";
import { Loading } from "../../routes";

function AdminLayout(props) {
  const [activeSideBar, setActiveSideBar] = useState("dashboard");
  const [notifications, setNotifications] = useState([]);
  const [unread, setUnread] = useState([]);
  const onFetchData = (status, payload) => {
    if (!status) {
      localStorage.removeItem(USERTOKEN);
      localStorage.removeItem(USERDATA);
      localStorage.removeItem(USERACCESSCONTROL);
      localStorage.clear();
      window.location.href =
        LOGIN_URL + `?redirect=${encodeURIComponent(props.location.pathname)}`;
      return null;
    } else {
      localStorage.setItem(USERDATA, JSON.stringify(payload.data));
      setNotifications(payload.data.data.notifications);
      setUnread(payload.data.data.unread);
      setLoading(false);
    }
  };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setNotifications(
      (props.location &&
        props.location.state &&
        props.location.state.notifications) ||
        []
    );
    let pathnameArray = props.history.location.pathname.split("/");
    if (
      pathnameArray.includes("subscribers") ||
      pathnameArray.includes("subscriber")
    )
      setActiveSideBar("subscribers");
    else if (
      pathnameArray.includes("account") ||
      pathnameArray.includes("accounts")
    )
      setActiveSideBar("account");
    else if (
      pathnameArray.includes("services") ||
      pathnameArray.includes("service")
    )
      setActiveSideBar("service");
    else if (
      pathnameArray.includes("campaign") ||
      pathnameArray.includes("campaigns")
    )
      setActiveSideBar("campaign");
    else if (
      pathnameArray.includes("finance") ||
      pathnameArray.includes("finances")
    )
      setActiveSideBar("finance");
    else if (
      pathnameArray.includes("settings") ||
      pathnameArray.includes("setting")
    )
      setActiveSideBar("settings");
    else if (pathnameArray.includes("support")) setActiveSideBar("support");
    else setActiveSideBar("dashboard");

    try {
      const token = localStorage.getItem(USERTOKEN);
      if (token) {
        axiosFunc(
          "get",
          getUser(),
          null,
          {
            Authorization: `Bearer ${localStorage.getItem(USERTOKEN)}`,
          },
          onFetchData
        );
      } else {
        //check refresh
        const queryData = qs.parse(window.location.search);
        if (queryData.refresh) {
          axiosFunc(
            "post",
            refreshUrl,
            {
              refresh: queryData.refresh,
            },
            null,
            (status, payload) => {
              if (status) {
                let redirectTo = null;
                let activeData = payload.data.data;
                if (activeData.user.redirectTo) {
                  redirectTo =
                    activeData.user.redirectTo + "?token=" + activeData.token;
                }
                localStorage.setItem(USERTOKEN, activeData.token);
                localStorage.setItem(USERDATA, JSON.stringify(activeData.user));

                if (activeData.user.role.toLowerCase() === "admin") {
                  localStorage.setItem(
                    USERACCESSCONTROL,
                    JSON.stringify(AdminAccessControl)
                  );
                  gotoSystem(activeData.user.notifications, redirectTo);
                  setLoading(false);
                } else {
                  if (activeData.user.profile.roleId) {
                    axiosFunc(
                      "get",
                      userUrl(`roles?roleId=${activeData.user.profile.roleId}`),
                      null,
                      "yes",
                      (status, data) => {
                        if (status) {
                          localStorage.setItem(
                            USERACCESSCONTROL,
                            JSON.stringify(SubProviderAccessControl)
                          );
                          gotoSystem(activeData.user.notifications, redirectTo);
                          setLoading(false);
                        } else {
                          Notification.bubble({
                            type: "error",
                            content:
                              "An error occurred while getting role information",
                          });
                        }
                      }
                    );
                  } else {
                    localStorage.setItem(
                      USERACCESSCONTROL,
                      JSON.stringify(ProviderAccessControl)
                    );
                    gotoSystem(activeData.user.notifications, redirectTo);
                    setLoading(false);
                  }
                }

                //after refreshing, reload page to effect changes
              } else {
                window.location.href =
                  LOGIN_URL +
                  `?redirect=${encodeURIComponent(props.location.pathname)}`;
              }
            }
          );
        } else {
          window.location.href =
            LOGIN_URL +
            `?redirect=${encodeURIComponent(props.location.pathname)}`;
        }
      }
    } catch (e) {
      // window.location.href = LOGIN_URL + `?redirect=${encodeURIComponent(props.location.pathname)}`;
    }
  }, [props]);
  const gotoSystem = (notifications = {}, redirectTo = null) => {
    if (redirectTo) {
      window.location.href = redirectTo;
      return;
    }
    let queryParams = qs.parse(props.location.search);
    if (has(queryParams, "redirect")) {
      props.history.push(`${queryParams.redirect || "/"}`, { notifications });
    } else {
      props.history.push("/admin", { notifications });
    }
  };

  if (loading) {
    return Loading;
  } else {
    return (
      <div className="layout-main">
        <ErrorBoundary>
          <NavBar {...props} />
        </ErrorBoundary>
        <ErrorBoundary>
          <SideBar {...props} unread={unread} activeSideBar={activeSideBar} />
        </ErrorBoundary>
        <div className={`content-main ${!props.navbar && "no-header"}`}>
          <ErrorBoundary>
            <Message notifications={notifications} />
            <br />
          </ErrorBoundary>
          {props.children}
        </div>
      </div>
    );
  }
}

AdminLayout.defaultProps = {
  navbar: true,
  activeSideBar: "",
};

AdminLayout.propTypes = {
  navbar: PropTypes.bool,
  activeSideBar: PropTypes.string,
};

export default withRouter(AdminLayout);
